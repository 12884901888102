import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FirstConnectionGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(): boolean {
    const stateResponse = true; // <----rappel : state api a recup apres login-employeur
    if (stateResponse) {
      try {
        return true;
      } catch (error) {
        console.error('Erreur lors du parsing du localStorage:', error);
        this.router.navigate(['/premiere-connexion']);
        return false;
      }
    } else {
      this.router.navigate(['adherent/premiere-connexion']);
      return false;
    }
  }
}
