import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { ENDPOINT } from 'src/app/constants/endpoints';
import { ApiService } from 'src/app/data/api.service';
import { DelegationService } from 'src/app/shared/delegation.service';

@Component({
  selector: 'app-list-employers',
  templateUrl: './list-employers.component.html',
  styleUrls:['../../../../app/views/app/app.component.scss']
})
export class ListEmployersComponent implements OnInit {
  public employers: any = [];
  constructor(
    private apiService: ApiService,
    private notifications: NotificationsService,
    private router: Router,
    private delegationService: DelegationService ,
  ) {}

  ngOnInit(): void {
    const endpoint = ENDPOINT.listeempaut.path;

    this.apiService.get('mdce', endpoint).subscribe({
      next: (response) => {
        if (response && response.Employeurs_autorises.length > 0) {
          this.employers = response.Employeurs_autorises;
        }
      },
      error: (error) => {
        this.onError('erreur', "Impossible d'afficher la liste des employeurs autorisés");
        console.error(error);
      },
    });
  }

  public selectEmployer(id: string, nameSociety: string) {
    this.delegationService.numEmployer = parseInt(id);
    this.router.navigate(['/employeur/app/delegations', id], {
      queryParams: { nameSociety: nameSociety },
    });
  }

  public onError(error: string, content: string): void {
    this.notifications.create(error, content, NotificationType.Error, {
      timeOut: 3000,
      showProgressBar: true,
    });
  }
}
