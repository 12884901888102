import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { environment } from 'src/environments/environment';
import { AuthGuard } from '../shared/auth.guard';
import { UserRole } from '../shared/auth.roles';
import { RedirectComponent } from './redirect/redirect.component';
import { FirstConnectionGuard } from '../shared/first-connexion.guard';
import { ListEmployersComponent } from './app/list-employers/list-employers.component';

const adminRoot = environment.adminRoot.substr(1); // path cannot start with a slash

let routes: Routes = [
  { path: '', component: RedirectComponent },
  {
    path: 'adherent',
    children: [
      { path: '', pathMatch: 'full', redirectTo: adminRoot },
      {
        path: adminRoot,
        loadChildren: () => import('./app/app.module').then((m) => m.AppModule),
        data: {
          roles: [
            UserRole.Admin,
            UserRole.Salarie,
            UserRole.Employeur,
            UserRole.Retraite,
            UserRole.Volontaire,
          ],
        },
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
      },
      { path: 'error', component: ErrorComponent },
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: '**', redirectTo: 'error' },
    ],
  },
  {
    path: 'employeur',
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'liste-employeurs'},
      { path: 'liste-employeurs', component: ListEmployersComponent },
      {
        path: adminRoot,
        loadChildren: () => import('./app/app.module').then((m) => m.AppModule),
        data: {
          roles: [
            UserRole.Admin,
            UserRole.Salarie,
            UserRole.Employeur,
            UserRole.Retraite,
            UserRole.Volontaire,
          ],
        },
        canActivate: [AuthGuard, FirstConnectionGuard],
        canActivateChild: [AuthGuard],
      },
      { path: 'error', component: ErrorComponent },
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: '**', redirectTo: '/error' },
    ],
  },
];

if (!environment.isAuthGuardActive) {
  routes = routes.map((r) => {
    if (r.path === 'app') {
      r = { path: 'app', loadChildren: () => import('./app/app.module').then((m) => m.AppModule) };
    }
    return r;
  });
}

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ViewRoutingModule {}
