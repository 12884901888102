import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DelegationService {
  private _numEmployer: number;

  constructor() {
    const storedValue = localStorage.getItem('numEmployer');
    if (storedValue) {
      this._numEmployer = parseInt(storedValue, 10);
    }
  }

  get numEmployer(): number {
    return this._numEmployer;
  }

  set numEmployer(value: number) {
    this._numEmployer = value;
    localStorage.setItem('numEmployer', value.toString());
  }
}
