<nav class="navbar fixed-top">
  <div class="d-flex align-items-center navbar-left">
    <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;"
      href="javascript:;" class="menu-button d-none d-md-block">
      <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg> {{" "}}
      <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
    </a>

    <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
      class="menu-button-mobile d-xs-block d-sm-block d-md-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>

    <div class="div-navbar-logo">
      <a class="navbar-logo" routerLink="/{{appPath}}{{adminRoot}}">
        <span class="logo d-block"></span>
      </a>
    </div>

  </div>

  <div class="navbar-right">

    <div class="user d-inline-block" dropdown>
      <button class="btn btn-empty p-0" type="button" dropdownToggle>
        <span class="name">{{displayName}}</span>
        <span class="mx-2">
          <i class="simple-icon-user profile-img-icon"></i>
        </span>
      </button>

      <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
        <a class="dropdown-item c-pointer" [routerLink]="'mon-compte'" *ngIf="currentUser.roles[0] !== 'employeur'"> Mon Compte </a>
        <a class="dropdown-item c-pointer" [routerLink]="'compte'" *ngIf="currentUser.roles[0] === 'employeur'"> Compte </a>
        <li class="divider dropdown-divider"></li>
        <a class="dropdown-item c-pointer" (click)="onSignOut()">Déconnexion</a>
      </div>
    </div>

  </div>
</nav>
